import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import firebase from 'firebase/compat/app';
import "firebase/compat/functions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerLink: {
      fontSize: "1rem",
      cursor: "pointer",
      marginTop: "15px",
      marginLeft: "40px",
      color: "mintcream",
      "&:hover": {
        color: "#fff",
      },
      ["@media (max-width:770px)"]: {
        marginLeft: "5px",
      }
    },
    privacy: {
    },
    button: {
      height: "50px",
      width: "180px",
      marginLeft: "50px",
      background: "linear-gradient(to right, rgba(241,193,193,255) 0%, rgba(245,227,138,255) 100%) !important",
      border: "1px solid black",
      cursor: "pointer",
      "& span.MuiButton-label": {
        fontSize: "1.33em !important",
        ["@media (max-width:770px)"]: {
          fontSize: "1em !important"
        }
      },
      ["@media (max-width:770px)"]: {
        height: "40px",
        width: "200px",
        paddingTop: "9px",
        marginTop: "10px",
        marginLeft: "20px"
      },
      ["@media (max-width:320px)"]: {
        marginLeft: 0
      }
    },
  })
)

const FooterContainer = styled('div')({
  width: "100vw",
  height: "100px",
  position: 'absolute',
  bottom: "-140px",
  paddingTop: "40px",
  background: "#333",
  ["@media (max-width:770px)"]: {
    paddingTop: "10px",
    height: "130px",
  },
  ["@media (max-width:320px)"]: {
    height: "150px"
  }
});

const Links = styled('div')({
  width: "90%",
  display: "flex",
  marginLeft: "5%",
  justifyContent: "center",
  flexWrap: "wrap",
  ["@media (max-width:770px)"]: {
    justifyContent: "space-around"
  }
});

const Contact = styled('div')({
  textAlign: "center",
  marginTop: "2rem",
  color: "mintcream",
  ["@media (max-width:770px)"]: {
    display: "none"
  }
})

export default function Footer(props:any) {
  const classes = useStyles();
  const openContact = props.openContact;
  const showPrivacyPolicy = props.openPrivacy;
  const showTerms = props.openTermsOfService;
  const showFaq = props.openFaq;
  const openAbout = props.openAbout;

  return (
    <FooterContainer>
      <Links>
        <div className={classes.footerLink} onClick={() => showFaq()}>
          FAQ
        </div>
        <div className={classes.footerLink} onClick={() => openContact()}>
          CONTACT
        </div>
        <div className={classes.footerLink} onClick={() => openAbout()}>
          ABOUT US
        </div>
        <div className={classes.footerLink} onClick={() => showPrivacyPolicy()}>
          PRIVACY POLICY
        </div>
        <div className={classes.footerLink} onClick={() => showTerms()}>
          TERMS OF USE
        </div>
        <div className={classes.footerLink} onClick={() => firebase.auth().signOut()}>
          LOGOUT
        </div>
      </Links>
      <Contact>
        For individuals with disabilities needing assistance accessing online information, contact us at astro@lanyadoo.com.
      </Contact>
    </FooterContainer>
  )
}
